<template>
    <div class="app_box">
         <!-- 加载动画 -->
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <Header></Header>
        <div class="app_cont">
            <div class="app_top">
                <img src="../../assets/head_img/phone.png" alt="" class="phone f_left">
                <div class="app_txt f_left">
                    <span class="app_tit">免费下载体验</span>
                    <span class="app_txt_detail">“马拉松报名”是一款官方合作的专业马拉松报名APP，现在下载 免费应用程序，即刻体验一站式报名服务。</span>
                    <div style="display:flex">
                    <vue-qr :logoSrc="img" :text="url" :size="220" :margin="8" :dotScale = '1'></vue-qr>
                    <!-- <vue-qr :logoSrc="img2" :text="url" :size="220" :margin="8" :dotScale = '1' style="margin-left:50px"> </vue-qr> -->
                    <img :src="img2" alt="" style="width:219px;margin-left:50px">
                    </div>
                </div>
            </div>
            <div class="app_bot"></div>
        </div>
        <Footer :footerBgColor="'#fff'"></Footer>
    </div>

</template>

<script>
import vueQr from 'vue-qr';
import linkedmeLink from '../../config/linkdeme';
export default {
    name:'app_view',
    components: {
        vueQr
    },
    data() {
        return {
            url:'',//二维码地址
            img:require('../../assets/public/favicon.png'),
            img2:require('../../assets/public/xcx.jpg'),
            isLoading:true,
            timer:null
        }
    },
    methods: {
        loading() {
            window.clearTimeout(this.timer)
            this.timer = window.setTimeout(()=> {
                this.isLoading = false;
            },2500)
        }
    },
    created() {
        // 二维码下载打开app
        linkedmeLink({
            path: '', //跳转app的名字
        }).then(res => {
            this.url = res.url;
            console.log(res);
        }),
        this.loading()
    },
    destroyed() {
        window.clearTimeout(this.timer);
    }

}
</script>

<style>
@import './style/app.css'
</style>
